import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  UrlField,
  DateField,
  Create,
  SimpleForm,
  FileInput,
  FileField
} from 'react-admin';
//import { Unarchive as UnarchiveIcon, AddBox as AddBoxIcon, List as ListIcon } from '@material-ui/icons';

const required = (message = 'Field required') =>
  (value, allValues, props) => value ? undefined : message;

const FileUploadRedirect = (basePath, id, data) => `/upload/files`

export const FileList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="ext" />
            <TextField source="mime" />
            <TextField source="size" />
            <UrlField source="url" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);

export const FileUpload = props => (
  <Create {...props}>
    <SimpleForm redirect={FileUploadRedirect}>
      <FileInput source="files" multiple={true} validate={required()} placeholder="Trascina il file qui">
        <FileField source="url" title="name" />
      </FileInput>
    </SimpleForm>
  </Create>
)

// export const menu = {
//   name: 'Upload',
//   icon: <UnarchiveIcon />,
//   items: [
//     {
//       name: 'Elenco upload',
//       path: '/upload/files',
//       icon: <ListIcon />
//     },
//     {
//       name: 'Nuovo upload',
//       path: '/upload/create',
//       icon: <AddBoxIcon />
//     }
//   ]
// }

export default {
  list: FileList,
  create: FileUpload
};