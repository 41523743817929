import React from 'react';
import {
  List,
  Datagrid,
  //TextField,
  DateField,
  RichTextField,
  DateInput,
  Edit,
  TabbedForm,
  FormTab,
  SelectInput,
  ReferenceInput,
  Create,
  ChipField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
//import { Chat as ChatIcon, AddBox as AddBoxIcon, List as ListIcon, ViewList as ViewListIcon } from '@material-ui/icons';

export const FaqList = props => (
  <List {...props} variant="outlined">
    <Datagrid rowClick="edit">
      <DateField source="Date" label="Data" locales="it-IT" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }} />
      <RichTextField source="Domanda" />
      {/* <TextField source="Category.Nome" label="Categoria" /> */}
      <ChipField source="Category.Nome" label="Categoria" />
    </Datagrid>
  </List>
);

export const FaqEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Proprietà">
        <DateInput source="Date" variant="outlined" />
        <ReferenceInput label="Category" source="Category._id" reference="faqcategories" perPage={100} variant="outlined">
          <SelectInput optionText={o => `${o.Nome} - ${o.Name}`} optionValue="id" />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Italiano">
        <RichTextInput source="Domanda" variant="outlined" />
        <RichTextInput source="Risposta" />
      </FormTab>
      <FormTab label="Inglese">
        <RichTextInput source="Question" variant="outlined" />
        <RichTextInput source="Answer" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const FaqCreate = props => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="Proprietà">
        <DateInput source="Date" variant="outlined" />
        <ReferenceInput label="Category" source="Category._id" reference="faqcategories" perPage={100} variant="outlined">
          <SelectInput optionText={o => `${o.Nome} - ${o.Name}`} optionValue="id" />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Italiano">
        <RichTextInput source="Domanda" variant="outlined" />
        <RichTextInput source="Risposta" />
      </FormTab>
      <FormTab label="Inglese">
        <RichTextInput source="Question" variant="outlined" />
        <RichTextInput source="Answer" />
      </FormTab>
    </TabbedForm>
  </Create>
);

// export const menu = {
//   name: 'FAQ',
//   icon: <ChatIcon />,
//   items: [
//     {
//       name: 'Elenco FAQ',
//       path: '/faqs',
//       icon: <ListIcon />
//     },
//     {
//       name: 'Argomenti FAQ',
//       path: '/faqcategories',
//       icon: <ViewListIcon />
//     },
//     {
//       name: 'Nuovo FAQ',
//       path: '/faqs/create',
//       icon: <AddBoxIcon />
//     }
//   ]
// }

export default {
  list: FaqList,
  edit: FaqEdit,
  create: FaqCreate
};