import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Logo from './logo.jpg'

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const TipspaAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar {...props}>
          <Typography
         	 variant="title"
          	color="inherit"
          	className={classes.title}
          	id="react-admin-title"
          />
          <img src={Logo} className={classes.logo} alt="Tamburi Investment Partners" />
          {/* <h2>Tamburi Investment Partners</h2> */}
          <span className={classes.spacer} />
        </AppBar>
    );
};

export default TipspaAppBar;