import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Pagination,
  Filter
} from 'react-admin';

export const FaqCategoryFilter = props => (
  <Filter {...props} variant="outlined">
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

const FaqCategoryListPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

export const FaqCategoryList = props => (
  <List {...props} filters={<FaqCategoryFilter />} pagination={<FaqCategoryListPagination />} perPage={50} title="Argomenti FAQ">
    <Datagrid rowClick="edit">
      {/* <TextField source="id" /> */}
      {/* <DateField source="createdAt" />
      <DateField source="updatedAt" /> */}
      <TextField source="Nome" />
      <TextField source="Name" />
    </Datagrid>
  </List>
);

export const FaqCategoryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="Nome" variant="outlined" />
      <TextInput source="Name" variant="outlined" />
    </SimpleForm>
  </Edit>
);

export const FaqCategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="Nome" variant="outlined" />
      <TextInput source="Name" variant="outlined" />
    </SimpleForm>
  </Create>
);

export default {
  list: FaqCategoryList,
  edit: FaqCategoryEdit,
  create: FaqCategoryCreate
}