import React from 'react';
import {
  Admin,
  Resource,
  Login
} from 'react-admin';
//import buildGraphQLProvider from 'ra-data-graphql-strapi';
import { buildDataProvider, buildAuthProvider } from '@doldigital/ra-data-strapi';
//import { config } from 'tamburi-config';

import article from './components/Article';
import category from './components/Category';
import event from './components/Event';
import page from './components/Page';
import faq from './components/Faq';
import upload from './components/Upload';
import eventcategory from './components/EventCategory';
import faqcategory from './components/FaqCategory';
import performance from './components/Performance';
//import { Layout, Theme } from './components/admin/layout';
import muiTheme from './theme/muiTheme';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import EventIcon from '@material-ui/icons/Event';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PublishIcon from '@material-ui/icons/Publish';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import TipspaLayout from './components/admin/layout/TipspaLayout';
//import { __schema as schema } from './schema.json';

// const env = process.env.NODE_ENV || 'development';
// const apiConfig = config[env];

const apiUrl = process.env.REACT_APP_APIURL || 'https://strapi.tmb.dol.it'

//const authProvider = buildAuthProvider(apiUrl);

const DolLoginPage = props => <Login {...props} backgroundImage="/bg-login-dol.jpg" />;

const App = props => {
  const strapi = buildDataProvider(apiUrl)
  const strapiAuth = buildAuthProvider(apiUrl)
  return (
    <Admin
      theme={muiTheme}
      dataProvider={strapi}
      authProvider={strapiAuth}
      loginPage={DolLoginPage}
      layout={TipspaLayout}
      >
      <Resource name="articles" {...article} options={{ label: 'Articoli' }} />
      <Resource name="categories" {...category} options={{ label: 'Argomenti Articoli' }} icon={DynamicFeedIcon} />
      <Resource name="events" {...event} options={{ label: 'Eventi' }} icon={EventIcon} />
      <Resource name="eventcategories" {...eventcategory} options={{ label: 'Argomenti Eventi' }} icon={DynamicFeedIcon} />
      <Resource name="pages" {...page} options={{ label: 'Pagine' }} icon={MenuBookIcon} />
      <Resource name="faqs" {...faq} options={{ label: 'FAQ' }} icon={QuestionAnswerIcon} />
      <Resource name="faqcategories" {...faqcategory} options={{ label: 'Argomenti FAQ' }} icon={DynamicFeedIcon} />
      {/* <Resource name="upload" create={FileUpload} /> */}
      <Resource name="upload/files" list={upload.list} icon={PublishIcon} />
      <Resource name="performances" {...performance} options={{ label: 'Performance' }} icon={EqualizerIcon}/>
    </Admin>
  )
}

export default App;
