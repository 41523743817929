import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin';
//import { Description as DescriptionIcon, List as ListIcon } from '@material-ui/icons';
import StrapiMediaInput from '@doldigital/ra-strapi-media';

export const PerformanceList = props => (
  <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
          <TextField source="Title" />
      </Datagrid>
  </List>
);

export const PerformanceEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="Title" label="Titolo" variant="outlined" fullWidth />
      <TextInput source="TitleEng" label="Title" variant="outlined" fullWidth />
      <ArrayInput source="Column1" variant="outlined">
        <SimpleFormIterator>
          <TextInput source="label" label="Intestazione" variant="outlined" fullWidth />
          <TextInput source="value" label="Valore" variant="outlined" fullWidth />
          <TextInput source="labeleng" label="Intestazione Inglese" variant="outlined" fullWidth />
          <TextInput source="valueeng" label="Valore Inglese" variant="outlined" fullWidth />
          <TextInput source="style" label="Personalizzazioni stile" format={v => JSON.stringify(v)} parse={v => JSON.parse(v)} variant="outlined" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="Column2" variant="outlined">
        <SimpleFormIterator>
          <TextInput source="label" label="Intestazione" variant="outlined" fullWidth />
          <TextInput source="value" label="Valore" variant="outlined" fullWidth />
          <TextInput source="labeleng" label="Intestazione Inglese" variant="outlined" fullWidth />
          <TextInput source="valueeng" label="Valore Inglese" variant="outlined" fullWidth />
          <TextInput source="style" label="Personalizzazioni stile" format={v => JSON.stringify(v)} parse={v => JSON.parse(v)} variant="outlined" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="Notes" variant="outlined">
        <SimpleFormIterator>
          <TextInput source="text" label="Nota" variant="outlined" fullWidth />
          <TextInput source="texteng" label="Nota Inglese" variant="outlined" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <StrapiMediaInput source="Slide1" />
      <StrapiMediaInput source="Slide2" />
      <StrapiMediaInput source="Slide3" />
      <StrapiMediaInput source="Slide1Eng" label="Slide 1 Inglese" />
      <StrapiMediaInput source="Slide2Eng" label="Slide 2 Inglese"/>
      <StrapiMediaInput source="Slide3Eng" label="Slide 3 Inglese"/>
    </SimpleForm>
  </Edit>
);

// export const menu = {
//   name: 'Performance',
//   icon: <DescriptionIcon />,
//   items: [
//     {
//       name: 'Dashboard',
//       path: '/performances',
//       icon: <ListIcon />
//     }
//   ]
// }

export default {
  list: PerformanceList,
  edit: PerformanceEdit
};
