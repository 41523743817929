import { createMuiTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import grey from '@material-ui/core/colors/grey';


const primaryCustomDark = blueGrey[900]
const primaryCustomLight = blueGrey[200]
const primaryCustomMain = blueGrey[500]

const secondaryCustomMain = grey[800]
const secondaryCustomMedium = grey[600]
const secondaryCustomDark = grey[900]
const secondaryCustomLight = grey[200]

const theme = createMuiTheme({
    palette: {
      primary: {
          light: primaryCustomLight,
          main: primaryCustomMain,
          dark: primaryCustomDark,
          contrastText: '#fff',
      },
      secondary: {
          light: secondaryCustomLight,
          main: secondaryCustomMain,
          dark: secondaryCustomDark,
          contrastText: '#fff',
      },
    },
    overrides: {
      MuiButton: {
          containedPrimary: {
              color: primaryCustomLight,
              backgroundColor: primaryCustomDark,
          }
      },
      MuiDrawer: {
          root: {
              minWidth: 20,
              background: '#fff',
              minHeight: '100vh',
              WebkitBoxShadow: '0 0.125rem 9.375rem rgba(90,97,105,.1), 0 0.25rem 0.5rem rgba(90,97,105,.12), 0 0.9375rem 1.375rem rgba(90,97,105,.1), 0 0.4375rem 2.1875rem rgba(165,182,201,.1)',
              MozBoxShadow: '0 0.125rem 9.375rem rgba(90,97,105,.1), 0 0.25rem 0.5rem rgba(90,97,105,.12), 0 0.9375rem 1.375rem rgba(90,97,105,.1), 0 0.4375rem 2.1875rem rgba(165,182,201,.1)',
              boxShadow: '0 0.125rem 9.375rem rgba(90,97,105,.1), 0 0.25rem 0.5rem rgba(90,97,105,.12), 0 0.9375rem 1.375rem rgba(90,97,105,.1), 0 0.4375rem 2.1875rem rgba(165,182,201,.1)',
          },
      },
      RaMenuItemLink: {
          root: {
              color: primaryCustomMain,
              "&:hover": {
                  backgroundColor: primaryCustomLight
              }
          },
          active: {
              color: '#fff',
              background: primaryCustomDark,
              '& svg': { color: '#fff' }
          }
      },
      MuiCard: {
        root:{
          WebkitBoxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)',
          MozBoxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)',
          boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)',
          borderRadius: '.625rem',
        }
      },
      MuiTypography: {
        h4:{
          color: primaryCustomDark,
        },
        subtitle1:{
          fontSize: '12px',
          color: secondaryCustomMedium,
        }
      },
      MuiOutlinedInput: {
        notchedOutline:{
          borderColor: 'rgba(0, 0, 0, 0.13)'
        },
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: 'rgba(0, 0, 0, 0.13)'
          }
        }
      },
      MuiAppBar: {
        colorSecondary: {
          backgroundColor: '#0b1f2c',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        root: {
          fontSize: '21px'
        }
      },
      
      MuiTableRow: {
        root: {
          "& .show-page": {
            background: '#fff',
            marginTop: '-25px',
            marginRight: '-24px',
            marginLeft: '-24px'
          },
          "& .MuiPaper-root": {
            WebkitBoxShadow: '0 0 0 rgba(90,97,105,.11)',
            MozBoxShadow: '0 0 0 rgba(90,97,105,.11)',
            boxShadow: '0 0 0 rgba(90,97,105,.11)',
            padding: '15px 25px 25px 25px',
          }
        }
      }
    },
});

export default theme;
