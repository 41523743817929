import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Pagination,
  Filter
} from 'react-admin';

export const EventCategoryFilter = props => (
  <Filter {...props} variant="outlined">
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

const EventCategoryListPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

export const EventCategoryList = props => (
  <List {...props} filters={<EventCategoryFilter />} pagination={<EventCategoryListPagination />} perPage={50} title="Argomenti eventi">
    <Datagrid rowClick="edit">
      {/* <TextField source="id" /> */}
      {/* <DateField source="createdAt" />
      <DateField source="updatedAt" /> */}
      <TextField source="Nome" />
      <TextField source="Name" />
    </Datagrid>
  </List>
);

export const EventCategoryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="Nome" variant="outlined" />
      <TextInput source="Name" variant="outlined" />
    </SimpleForm>
  </Edit>
);

export const EventCategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="Nome" variant="outlined" />
      <TextInput source="Name" variant="outlined" />
    </SimpleForm>
  </Create>
);

export default {
  list: EventCategoryList,
  edit: EventCategoryEdit,
  create: EventCategoryCreate
}