import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  Pagination,
  TextInput,
  DateInput,
  ReferenceInput,
  //SelectInput,
  Edit,
  Create,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  ChipField
} from 'react-admin';
import StrapiMediaInput from '@doldigital/ra-strapi-media';
//import { Event as EventIcon, AddBox as AddBoxIcon, List as ListIcon, ViewList as ViewListIcon } from '@material-ui/icons';

export const EventFilter = props => (
  <Filter {...props} variant="outlined">
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Title" source="Title" />
    <TextInput label="Titolo" source="Titolo" />
    <DateInput source="Date" label="Data" />
    <ReferenceInput label="Category" source="Category" reference="eventcategories">
      <AutocompleteInput optionText={o => `${o.Nome} - ${o.Name}`} optionValue="id" />
    </ReferenceInput>
  </Filter>
)

const EventListPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

export const EventList = props => (
  <List {...props} filters={<EventFilter />} pagination={<EventListPagination />} perPage={50} title="Argomenti eventi">
    <Datagrid rowClick="edit">
      <DateField source="Date" locales="it-IT" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }} />
      <TextField label="Titolo IT" source="Titolo" />
      <TextField label="Titolo EN" source="Title" />
      {/* <TextField label="Argomento" source="Category.Name" /> */}
      <ChipField source="Category.Name" label="Argomento" />
    </Datagrid>
  </List>
);

export const EventEdit = props => (
  <Edit {...props} title="Modifica evento">
    <TabbedForm>
      <FormTab label="Proprietà">
        <DateInput source="Date" label="Data" variant="outlined" />
        <ReferenceInput label="Category" source="Category._id" reference="eventcategories" perPage={50} variant="outlined">
          <AutocompleteInput optionText={o => `${o.Nome} - ${o.Name}`} optionValue="id" />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Italiano">
        <TextInput source="Titolo" fullWidth={true} variant="outlined" />
        <StrapiMediaInput source="Allegato" />
      </FormTab>
      <FormTab label="Inglese">
        <TextInput source="Title" fullWidth={true} variant="outlined" />
        <StrapiMediaInput source="Attachment" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const EventCreate = props => (
  <Create {...props} title="Modifica evento">
    <TabbedForm>
      <FormTab label="Proprietà">
        <DateInput source="Date" label="Data" variant="outlined" />
        <ReferenceInput label="Category" source="Category" reference="eventcategories" perPage={50} variant="outlined">
          <AutocompleteInput optionText={o => `${o.Nome} - ${o.Name}`} optionValue="id" />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Italiano">
        <TextInput source="Titolo" fullWidth={true} variant="outlined" />
        <StrapiMediaInput source="Allegato" />
      </FormTab>
      <FormTab label="Inglese">
        <TextInput source="Title" fullWidth={true} variant="outlined" />
        <StrapiMediaInput source="Attachment" />
      </FormTab>
    </TabbedForm>
  </Create>
);

// export const menu = {
//   name: 'Eventi',
//   icon: <EventIcon />,
//   items: [
//     {
//       name: 'Elenco eventi',
//       path: '/events',
//       icon: <ListIcon />
//     },
//     {
//       name: 'Argomenti eventi',
//       path: '/eventcategories',
//       icon: <ViewListIcon />
//     },
//     {
//       name: 'Nuovo evento',
//       path: '/events/create',
//       icon: <AddBoxIcon />
//     }
//   ]
// }

export default {
  list: EventList,
  edit: EventEdit,
  create: EventCreate
};