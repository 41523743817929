import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceInput,
  SelectInput,
  Filter,
  DateInput
} from 'react-admin'
//import RichTextInput from 'ra-input-rich-text';
import { LibraryBooks as LibraryBooksIcon, AddBox as AddBoxIcon, List as ListIcon } from '@material-ui/icons';
import AdvancedInput from '@doldigital/ra-ckeditor-adv-input'
import StrapiMediaInput from '@doldigital/ra-strapi-media'

export const PageFilter = props => (
  <Filter {...props} variant="outlined">
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Title" source="Title" />
    <TextInput label="Titolo" source="Titolo" />
    <DateInput source="Date" label="Data" />
    <ReferenceInput label="Articoli" source="Category" reference="categories">
      <SelectInput optionText={o => `${o.Nome} - ${o.Name}`} optionValue="id" />
    </ReferenceInput>
    <ReferenceInput label="Eventi" source="EventCategory" reference="eventcategories">
      <SelectInput optionText={o => `${o.Nome} - ${o.Name}`} optionValue="id" />
    </ReferenceInput>
  </Filter>
)

export const PageList = props => (
  <List {...props} title="Pagine" perPage={50} filters={<PageFilter />} >
    <Datagrid rowClick="edit">
      <TextField source="Title" />
      <TextField source="Titolo" />
    </Datagrid>
  </List>
)

const PageEditTitle = ({ record }) => (
  <span>Modifica articolo "{record.Titolo} - {record.Title}"</span>
)

export const PageEdit = props => (
  <Edit {...props} title={<PageEditTitle />}>
    <TabbedForm>
      <FormTab label="Proprietà">
        <ReferenceInput label="Articoli" source="Category._id" reference="categories" variant="outlined">
          <SelectInput optionText={o => `${o.Nome} - ${o.Name}`} optionValue="id" />
        </ReferenceInput>
        <ReferenceInput label="Eventi" source="EventCategory._id" reference="eventcategories" variant="outlined">
          <SelectInput optionText={o => `${o.Nome} - ${o.Name}`} optionValue="id" />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Italiano">
        <TextInput source="Titolo" variant="outlined" />
        <AdvancedInput source="Testo" />
        <StrapiMediaInput source="Allegato" />
      </FormTab>
      <FormTab label="Inglese">
        <TextInput source="Title" variant="outlined" />
        <AdvancedInput source="Text" />
        <StrapiMediaInput source="Attachment" />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const PageCreate = props => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="Proprietà">
        <ReferenceInput label="Articoli" source="Category" reference="categories" variant="outlined">
          <SelectInput optionText="Name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput label="Eventi" source="EventCategory" reference="eventcategories" variant="outlined">
          <SelectInput optionText="Name" optionValue="id" />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Italiano">
        <TextInput source="Titolo" variant="outlined" />
        <StrapiMediaInput source="Allegato" />
        <AdvancedInput source="Testo" />
      </FormTab>
      <FormTab label="Inglese">
        <TextInput source="Title" variant="outlined" />
        <StrapiMediaInput source="Attachment" />
        <AdvancedInput source="Text" />
      </FormTab>
    </TabbedForm>
  </Create>
)

export const menu = {
  name: 'Pagine',
  icon: <LibraryBooksIcon />,
  items: [
    {
      name: 'Elenco pagine',
      path: '/pages',
      icon: <ListIcon />
    },
    {
      name: 'Nuova pagina',
      path: '/pages/create',
      icon: <AddBoxIcon />
    }
  ]
}

export default {
  list: PageList,
  edit: PageEdit,
  create: PageCreate
};