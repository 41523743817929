import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  DateInput,
  Edit,
  AutocompleteInput,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceInput,
  BooleanInput,
  Create,
  Filter,
  Pagination,
  ChipField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
//import { Description as DescriptionIcon, AddBox as AddBoxIcon, List as ListIcon, ViewList as ViewListIcon } from '@material-ui/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  Show,
  SimpleShowLayout
} from 'react-admin';
import { Grid} from '@material-ui/core';
import StrapiMediaInput from '@doldigital/ra-strapi-media';
//import { Card, CardContent, Button } from '@material-ui/core';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ArticleFilter = props => (
  <Filter {...props} variant="outlined">
    <TextInput label="Search" source="q" alwaysOn/>
    <TextInput label="Title" source="Title" />
    <TextInput label="Titolo" source="Titolo" />
    <DateInput source="Date" label="Data" />
    <ReferenceInput label="Category" source="Category" reference="categories">
      <AutocompleteInput optionText={o => `${o.Nome} - ${o.Name}`} optionValue="id" />
    </ReferenceInput>
  </Filter>
)

const ArticleListPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

const ArticleEditTitle = ({record}) => (
  <span>Modifica articolo "{record.Titolo} - {record.Title}"</span>
)

const ArticleListRowExpand = props => {
  const { record } = props;
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Grid container justify="center" spacing={2}>
          <Grid item md={12} lg={6} >
            {record.Attachment && (
              <>
              <h3>EN</h3>
              <Document file={record.Attachment.url} className="col">
                <Page pageNumber={1} />
              </Document>
              </>
            )}
          </Grid>
          <Grid item md={12} lg={6}>
            {record.Allegato && (
              <>
              <h3>IT</h3>
              <Document file={record.Allegato.url} className="col">
                <Page pageNumber={1} />
              </Document>
              </>
            )}
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
}

export const ArticleList = props => (
  <List {...props} title="Articoli" perPage={50} filters={<ArticleFilter />} pagination={<ArticleListPagination />} sort={{ field: 'Date', order: 'DESC'}}>
    <Datagrid rowClick="edit" expand={<ArticleListRowExpand />}>
      {/* <TextField source="id" /> */}
      <DateField source="Date" locales="it-IT" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }} />
      <TextField source="Title" label="Titolo EN" />
      <TextField source="Titolo" label="Titolo IT" />
      {/* <TextField label="Argomento" source="Category.Nome" /> */}
      <ChipField source="Category.Nome" label="Argomento" />
    </Datagrid>
  </List>
);

export const ArticleEdit = props => (
  <Edit {...props} title={<ArticleEditTitle />}>
    <TabbedForm>
      <FormTab label="Proprietà">
        <DateInput source="Date" label="Data" variant="outlined" />
        <ReferenceInput label="Category" source="Category._id" reference="categories" variant="outlined">
          <AutocompleteInput optionText={o => `${o.Nome} - ${o.Name}`} optionValue="id" />
        </ReferenceInput>
        <BooleanInput source="Sticky" label="In Evidenza" variant="outlined" />
        <BooleanInput source="ShowEng" label="Solo versione Inglese" variant="outlined" />
        <BooleanInput source="ShowIta" label="Solo versione Italiana" variant="outlined" />
      </FormTab>
      <FormTab label="Italiano">
        <TextInput source="Titolo" fullWidth={true} variant="outlined" />
        <RichTextInput source="Testo" />
        <StrapiMediaInput source="Allegato" />
      </FormTab>
      <FormTab label="Inglese">
        <TextInput source="Title" fullWidth={true} variant="outlined" />
        <RichTextInput source="Text" />
        <StrapiMediaInput source="Attachment" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

const ArticleCreateValidation = values => {
  let errors = {},
    itErrors = {}
    // ,
    // enErrors = {}
    ;
  if((values.Titolo || '').length === 0) {
    itErrors['Titolo'] = ['Titolo obbligatorio'];
  }
  if((values.Testo || '').length === 0) {
    itErrors['Testo'] = ['Testo obbligatorio'];
  }

  return errors;
}

export const ArticleCreate = props => (
  <Create {...props}>
    <TabbedForm validate={ArticleCreateValidation} defaultValue={{ Date: new Date() }}>
      <FormTab label="Proprietà">
        <DateInput source="Date" label="Data" />
        <ReferenceInput label="Category" source="Category" reference="categories">
          <AutocompleteInput optionText={o => `${o.Nome} - ${o.Name}`} optionValue="id" />
        </ReferenceInput>
        <BooleanInput source="Sticky" label="In Evidenza" />
        <BooleanInput source="ShowEng" label="Solo versione Inglese" />
        <BooleanInput source="ShowIta" label="Solo versione Italiana" />
      </FormTab>
      <FormTab label="Italiano">
        <TextInput source="Titolo" />
        <RichTextInput source="Testo" />
        <StrapiMediaInput source="Allegato" />
      </FormTab>
      <FormTab label="Inglese">
        <TextInput source="Title" />
        <RichTextInput source="Text" />
        <StrapiMediaInput source="Attachment" />
      </FormTab>
    </TabbedForm>
  </Create>
);

// export const menu = {
//   name: 'Articoli',
//   icon: <DescriptionIcon />,
//   items: [
//     {
//       name: 'Elenco articoli',
//       path: '/articles',
//       icon: <ListIcon />
//     },
//     {
//       name: 'Argomenti articoli',
//       path: '/categories',
//       icon: <ViewListIcon />
//     },
//     {
//       name: 'Nuovo articolo',
//       path: '/articles/create',
//       icon: <AddBoxIcon />
//     }
//   ]
// }

export default {
  list: ArticleList,
  edit: ArticleEdit,
  create: ArticleCreate
};